const _jsxFileName = "/home/mstein/dev/open.DASH/opendash/apps/demo/app/js/components/OverViewComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  Loading,

  useOpenDashServices,
  useSource,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Map, Marker } from "@opendash/plugin-geo-maplibre";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import { Description } from "@opendash/ui";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Layout,
  List,
  Row,
  Tooltip,
  Tree,
} from "antd";
import * as React from "react";

export default () => {
  const { SourceService } = useOpenDashServices();
  const sources = SourceService.getAll();
  const [collapsed, setCollapsed] = React.useState(true);
  const [currentSource, setCurrentSource] = useSource();
  const map = React.useRef(null);

  const mapSourceToTree = (
    source,
    level,
    colorIndex
  ) => {
    const children = sources
      .filter((cSource) => cSource.parent === source.id)
      .map((cSource) => mapSourceToTree(cSource, level + 1, colorIndex));
    const start = //@ts-ignore
      Highcharts.getOptions().colors[
        //@ts-ignore
        colorIndex % Highcharts.getOptions().colors.length
      ];
    const color = start;
    // let color = cBlend(
    //   level / 5,
    //   start,
    //   //@ts-ignore
    //   "#eeeeee",
    //   true
    // );

    return {
      id: source.id,
      key: source.tag ? source.tag : source.id,
      label: source.name,
      title: source.name,
      meta: source.meta,
      level,
      color,
      icon: (
        React.createElement(Avatar, {
          size: !collapsed ? 32 : 32 /*/ Math.pow(1.3, level)*/,
          style: {
            color: "var(--opendash-color-white)",
            backgroundColor: color,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}

          , source.name
            .split(" ")
            .map((part) => part[0])
            .join("")
        )
      ),
      children: children.length > 0 ? children : null,
    };
  };

  const sourceTree = sources
    .filter((cSource) => !cSource.parent)
    .map((cSource) => mapSourceToTree(cSource, 0, 0));

  console.log(sourceTree);
  const config = {
    _history: {
      aggregation: false,
      live: true,
      limit: 1,
    },
    _sources: [],
    _items: [],
    _dimensions: currentSource.meta.kpiData || [],
    title: currentSource.name || "No Name",
    description: currentSource.meta.description || "no description",
  };

  const renderSourceItem = (item) => {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Tooltip, { title: item.label, placement: "right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
          , React.createElement(List.Item, {
            style: {
              cursor: "pointer",
              borderRight:
                currentSource.id === item.id ? "4px solid " + item.color : null,
            },
            onClick: () => {
              setCurrentSource(item.id);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}

            , React.createElement(List.Item.Meta, {
              style: { marginLeft: collapsed ? 20 : 10 + item.level * 20 },
              avatar: item.icon,
              title: !collapsed ? item.label : "",
              description: !collapsed ? item.key : "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
            )
          )
        )
        , item.children &&
          item.children.map((child) => {
            return renderSourceItem(child);
          })
      )
    );
  };
  if (!sources || sources.length == 0) {
    return React.createElement(Loading, { message: "Loading...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} );
  }
  React.useEffect(() => {
    setTimeout(() => {
      map.current.resize();
    }, 500);
  }, []);

  return (
    React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
      , React.createElement(Layout.Sider, {
        width: "20%",
        collapsible: true,
        collapsed: false,
        //onCollapse={(value) => setCollapsed(value)}
        theme: "light", __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}

        , React.createElement(Tree, {
          treeData: sourceTree,
          onSelect: (titles, e) => {
            setCurrentSource(e.node.id);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
)
      )
      , React.createElement(Layout.Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
        , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
          , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
            , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
              , React.createElement(Map, {
                width: "100%",
                height: 400,
                mapRef: map,
                initialLongitude: 
                  _optionalChain([currentSource, 'access', _ => _.meta, 'access', _2 => _2.location, 'optionalAccess', _3 => _3.longitude]) || 10.447683
                ,
                initialLatitude: 
                  _optionalChain([currentSource, 'access', _4 => _4.meta, 'access', _5 => _5.location, 'optionalAccess', _6 => _6.latitude]) || 51.163375
                ,
                initialZoom: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}

                , React.createElement(Marker, {
                  longitude: 
                    _optionalChain([currentSource, 'access', _7 => _7.meta, 'access', _8 => _8.location, 'optionalAccess', _9 => _9.longitude]) || 10.447683
                  ,
                  latitude: _optionalChain([currentSource, 'access', _10 => _10.meta, 'access', _11 => _11.location, 'optionalAccess', _12 => _12.latitude]) || 51.163375, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
                )
              )
            )
          )
        )
        , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
          , React.createElement(Col, { span: 8, style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
            , React.createElement('div', { style: { paddingTop: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}
              , React.createElement(Description, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}, "Overview")
              , config._dimensions.length === 0 && (
                React.createElement(React.Fragment, null
                  , React.createElement(Empty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 183}})
                )
              )
              , config._dimensions.length > 0 && (
                React.createElement('div', { style: { marginBottom: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
                  , React.createElement(WidgetStatic, {
                    // key={"state.visualisation" + "~" + "asd"}
                    config: config,
                    type: "opendash-widget-hc-multi-kpi", __self: this, __source: {fileName: _jsxFileName, lineNumber: 188}}
                  )
                )
              )
              , React.createElement(Button, {
                type: "primary",
                ghost: true,
                style: { marginLeft: "10px", width: "calc(100% - 20px)" },
                icon: React.createElement(Icon, { icon: "fa:plus", __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
)
            )
          )
          , React.createElement(Col, { span: 16, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}})
        )
      )
    )
  );
};
